<footer class="container-fluid">
	<div class="row maxwidth-1264 mx-auto">
		<div class="col">
			<div class="row">
				<div class="col-auto col-sm-12 col-xl me-lg-5 pt-3 pt-lg-0 mx-auto mx-lg-0 mb-4 mb-xl-0">
					<p class="footer-logo">
						<a [routerLink]="['/']"><img src="//assets.powerkiosk.com/web/images/pk-logo-horiz-fullColor-white.svg" class="Power Kiosk"></a>
					</p>
					<p class="ps-5">&copy; {{ year }} All Rights Reserved</p>
				</div>
				<div class="col-12 col-sm-6 col-lg mb-4 mb-lg-0 pt-lg-3">
					<h4>Partner With Us</h4>
					<p><a [routerLink]="['/partners', 'energy-advisor']" class="footer-link">Energy Advisor</a></p>
					<p><a [routerLink]="['/partners', 'commercial-and-enterprise']" class="footer-link">Commercial &amp; Enterprise</a></p>
					<p><a [routerLink]="['/partners', 'affiliate']" class="footer-link">Affiliate</a></p>
					<p><a [routerLink]="['/partners', 'energy-supplier']" class="footer-link">Energy Supplier</a></p>
				</div>
				<div class="col-12 col-sm-6 col-lg pt-lg-3">
					<h4>About Us</h4>
					<p><a [routerLink]="['/about-us', 'leadership-team']" class="footer-link">Leadership Team</a></p>
					<p><a [routerLink]="['/about-us', 'blog']" class="footer-link">Blog</a></p>
					<p><a [routerLink]="['/about-us', 'testimonials']" class="footer-link">Testimonials</a></p>
					<h4 class="mt-4 mb-2 no-underline"><a [routerLink]="['contact-us']">Contact Us</a></h4>
          <p><a [routerLink]="['/privacy-policy']" class="footer-link">Privacy Policy</a></p>
				</div>
				<div class="col-12 col-sm-6 col-lg-auto pt-lg-3">
					<a href="https://www.linkedin.com/company/power-kiosk/" class="footer-link"><fa-icon [icon]="['fab', 'linkedin']"></fa-icon></a>
				</div>
			</div>
		</div>
	</div>
</footer>

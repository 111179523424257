<ul class="navbar-nav me-auto mb-2 mb-lg-0" [ngClass]="customClass ? customClass + '-nav' : ''">
	<li class="nav-item">
		<a class="nav-link" [routerLink]="['/']" (click)="closeCanvas()">Home</a>
	</li>
	<div ngbDropdown class="nav-item">
		<a ngbDropdownToggle href="javascript:void(0)" class="nav-link" role="button">
			Partner With Us
		</a>
		<div ngbDropdownMenu>
			<a ngbDropdownItem [routerLink]="['/partners', 'energy-advisor']" (click)="closeCanvas()"><fa-icon [icon]="['fas', 'caret-right']"></fa-icon>Energy Advisor</a>
			<a ngbDropdownItem [routerLink]="['/partners', 'commercial-and-enterprise']" (click)="closeCanvas()"><fa-icon [icon]="['fas', 'caret-right']"></fa-icon>Commercial &amp; Enterprise</a>
			<a ngbDropdownItem [routerLink]="['/partners', 'affiliate']" (click)="closeCanvas()"><fa-icon [icon]="['fas', 'caret-right']"></fa-icon>Affiliate</a>
			<a ngbDropdownItem [routerLink]="['/partners', 'energy-supplier']" (click)="closeCanvas()"><fa-icon [icon]="['fas', 'caret-right']"></fa-icon>Energy Supplier</a>
		</div>
	</div>
	<div ngbDropdown class="nav-item">
		<a ngbDropdownToggle href="javascript:void(0)" class="nav-link" role="button">
			About Us
		</a>
		<div ngbDropdownMenu>
			<a ngbDropdownItem [routerLink]="['/about-us', 'leadership-team']" (click)="closeCanvas()"><fa-icon [icon]="['fas', 'caret-right']"></fa-icon>Leadership Team</a>
			<a ngbDropdownItem [routerLink]="['/about-us', 'blog']" (click)="closeCanvas()"><fa-icon [icon]="['fas', 'caret-right']"></fa-icon>Blog</a>
			<a ngbDropdownItem [routerLink]="['/about-us', 'testimonials']" (click)="closeCanvas()"><fa-icon [icon]="['fas', 'caret-right']"></fa-icon>Testimonials</a>
		</div>
	</div>
	<li class="nav-item">
		<a class="nav-link" [routerLink]="['/contact-us']" (click)="closeCanvas()">Contact Us</a>
	</li>
</ul>
<ul class="navbar-nav mb-2 mb-lg-0" [ngClass]="customClass ? customClass + '-nav' : ''">
	<li class="nav-item pt-1" id="mainNavigationDemo">
		<a href="javascript:void(0);" pkWebCalendlyWidget class="btn btn-secondary nav-btn schedule-demo" id="scheduleDemoHeader">Schedule a Discovery Call</a>
	</li>
	<li class="nav-item">
		<a class="nav-link" [attr.href]="dashboardLogin" target="_blank">Dashboard Login</a>
	</li>
</ul>
